import React from 'react';
// interfaces
import { IImage } from '../../interfaces/image';
// styles
import styles from './hero-fullscreen-side-images.module.scss';

interface IProps {
  title: string;
  description?: string;
  buttonLabel?: string;
  buttonAction?: () => void;
  imageLeft: IImage;
  imageRight: IImage;
}

const HeroFullscreenSideImages: React.FC<IProps> = ({
  title,
  description,
  buttonLabel,
  buttonAction,
  imageLeft,
  imageRight,
}: IProps) => (
  <section className={styles.hero}>
    <div className={styles.row}>
      <div
        className={styles.imageLeft}
        style={{
          backgroundImage: `url("${process.env.GATSBY_MEDIA_URL}${imageLeft.filename_disk}")`,
        }}
      />
      <div className={styles.textContainer}>
        <div className={styles.container}>
          {title && <h1>{title}</h1>}
          {title && description && <hr />}
          {description && <p>{description}</p>}
          {buttonLabel && buttonAction && (
            <button className={styles.buttonPrimary} onClick={buttonAction}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
      <div
        className={styles.imageRight}
        style={{
          backgroundImage: `url("${process.env.GATSBY_MEDIA_URL}${imageRight.filename_disk}")`,
        }}
      />
    </div>
  </section>
);

export default HeroFullscreenSideImages;
