import React, { useEffect } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import SectionStandard from '../../components/section-standard';
import HeroFullscreen from '../../components/hero-fullscreen';
import HeroFullscreenSideImages from '../../components/hero-fullscreen-side-images';
// services
import { trackEvent } from '../../services/tracking/tracking';

const VitaminsPage = () => {
  const content = useStaticQuery(graphql`
    query Vitamins {
      directusVitamin {
        page_title
        page_description
        hero_title
        hero_description
        hero_image {
          title
          filename_disk
        }
        section_1_title
        section_1_description
        section_1_button_label
        section_1_image {
          filename_disk
          title
        }
        section_2_title
        section_2_description
        section_2_button_label
        section_2_image {
          filename_disk
          title
        }
        section_3_title
        section_3_description
        section_3_button_label
        section_3_image {
          filename_disk
          title
        }
        section_4_title
        section_4_description
        section_4_button_label
        section_4_image {
          filename_disk
          title
        }
        section_5_title
        section_5_description
        section_5_button_label
        section_5_image {
          filename_disk
          title
        }
        section_6_title
        section_6_description
        section_6_button_label
        section_6_image {
          filename_disk
          title
        }
        section_7_title
        section_7_description
        section_7_button_label
        section_7_image {
          filename_disk
          title
        }
        section_last_title
        section_last_description
        section_last_button_label
        section_last_image_left {
          filename_disk
        }
        section_last_image_right {
          filename_disk
        }
      }
    }
  `);

  const { directusVitamin } = content;
  const {
    page_title,
    page_description,
    hero_title,
    hero_description,
    hero_image,
    section_1_title,
    section_1_description,
    section_1_button_label,
    section_1_image,
    section_2_title,
    section_2_description,
    section_2_button_label,
    section_2_image,
    section_3_title,
    section_3_description,
    section_3_button_label,
    section_3_image,
    section_4_title,
    section_4_description,
    section_4_button_label,
    section_4_image,
    section_5_title,
    section_5_description,
    section_5_button_label,
    section_5_image,
    section_6_title,
    section_6_description,
    section_6_button_label,
    section_6_image,
    section_7_title,
    section_7_description,
    section_7_button_label,
    section_7_image,
    section_last_title,
    section_last_description,
    section_last_button_label,
    section_last_image_left,
    section_last_image_right,
  } = directusVitamin;

  // tracking
  useEffect(() => {
    trackEvent('Product List Viewed', {
      list_id: 'Vitamins',
      category: 'Vitamins',
    });
  }, []);

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <HeroFullscreen title={hero_title} description={hero_description} image={hero_image} loadingPlans={true} />

      <SectionStandard
        title={section_1_title}
        description={section_1_description}
        buttonLabel={section_1_button_label}
        buttonAction={() => navigate('/product/essential-one')}
        image={section_1_image}
      />

      <SectionStandard
        title={section_2_title}
        description={section_2_description}
        buttonLabel={section_2_button_label}
        buttonAction={() => navigate('/product/personalised')}
        image={section_2_image}
        direction="Right"
      />

      <SectionStandard
        title={section_3_title}
        description={section_3_description}
        buttonLabel={section_3_button_label}
        buttonAction={() => navigate('/products/health-goals')}
        image={section_3_image}
      />

      <SectionStandard
        title={section_4_title}
        description={section_4_description}
        buttonLabel={section_4_button_label}
        buttonAction={() => navigate('/product/vitamin-d')}
        image={section_4_image}
        direction="Right"
      />

      <SectionStandard
        title={section_7_title}
        description={section_7_description}
        buttonLabel={section_7_button_label}
        buttonAction={() => navigate('/product/omega-3')}
        image={section_7_image}
      />

      <SectionStandard
        title={section_5_title}
        description={section_5_description}
        buttonLabel={section_5_button_label}
        buttonAction={() => navigate('/product/immune-support-tin')}
        image={section_5_image}
        direction="Right"
      />

      <SectionStandard
        title={section_6_title}
        description={section_6_description}
        buttonLabel={section_6_button_label}
        buttonAction={() => navigate('/products/womens-health')}
        image={section_6_image}
      />

      <HeroFullscreenSideImages
        title={section_last_title}
        description={section_last_description}
        buttonLabel={section_last_button_label}
        buttonAction={() => navigate('/consultation')}
        imageLeft={section_last_image_left}
        imageRight={section_last_image_right}
      ></HeroFullscreenSideImages>
    </Layout>
  );
};

export default VitaminsPage;
